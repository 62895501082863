import React from 'react';
import { Link } from 'react-router-dom';
import { extractFirstImageUrl } from '../../utils/contentUtils';
import { extractSlug } from '../../utils/urlUtils';
import './PoetryCard.css';

const PoetryCard = ({ title, description, link, content }) => {
  const stripHtml = (html) => {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    temp.querySelectorAll('p').forEach(p => {
      if (p.textContent.includes('first appeared on mediumorchid-rhinoceros-290244.hostingersite.com')) {
        p.remove();
      }
    });
    const plainText = temp.textContent || temp.innerText || '';
    return plainText.split('[...]')[0].trim();
  };

  const imageUrl = extractFirstImageUrl(content);

  return (
    <Link to={`/poetry/${extractSlug(link)}`} className="poetry-card">
      {imageUrl && (
        <div className="card-image-container">
          <img src={imageUrl} alt={title} className="card-image" />
        </div>
      )}
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{stripHtml(description)}</p>
    </Link>
  );
};

export default PoetryCard;
