import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="site-footer">
      {/* <div className="footer-section">
        <h4>Use cases</h4>
        <ul>
          <li>UI design</li>
          <li>UX design</li>
          <li>Wireframing</li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>Explore</h4>
        <ul>
          <li>Design</li>
          <li>Prototyping</li>
          <li>Development features</li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>Resources</h4>
        <ul>
          <li>Blog</li>
          <li>Best practices</li>
          <li>Support</li>
        </ul>
      </div> */}
    </footer>
  );
};

export default Footer;
