import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PoetryCard from '../components/PoetryCard/PoetryCard';
import StoryCard from '../components/StoryCard/StoryCard';
import { fetchRSSFeed, filterAndLimitItems } from '../services/rssService';
import '../styles/Typography.css';

const Home = () => {
  const [poems, setPoems] = useState([]);
  const [stories, setStories] = useState([]);

  useEffect(() => {
    const loadFeed = async () => {
      const items = await fetchRSSFeed();
      setPoems(filterAndLimitItems(items, 'Poems', 3));
      setStories(filterAndLimitItems(items, 'Stories', 3));
    };
    loadFeed();
  }, []);

  return (
    <main className="main-content">
      <h2 className="page-title">Latest Works</h2>
      <section className="poetry-section">
        <h3 className="section-title">Poetry</h3>
        <div className="poetry-grid">
          {poems.map(poem => (
            <PoetryCard
              key={poem.link}
              title={poem.title}
              description={poem.description}
              link={poem.link}
              content={poem.content}
            />
          ))}
        </div>
        <p style={{textAlign: 'right'}}>
          <Link to="/poetry" className="more-link">more poems</Link>
        </p>
      </section>

      <section className="stories-section">
        <h3 className="section-title">Stories</h3>
        <div className="stories-list">
          {stories.map(story => (
            <StoryCard
              key={story.link}
              title={story.title}
              description={story.description}
              link={story.link}
              content={story.content}
            />
          ))}
        </div>
        <p style={{textAlign: 'right'}}>
          <Link to="/stories" className="more-link">more stories</Link>
        </p>
      </section>
    </main>
  );
};

export default Home; 