export async function fetchRSSFeed() {
  const response = await fetch('https://blog.mqhovey.com/feed/');
  const text = await response.text();
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(text, 'text/xml');
  
  const items = xmlDoc.querySelectorAll('item');
  const parsedItems = [];

  items.forEach(item => {
    const contentNode = Array.from(item.childNodes).find(
      node => node.nodeName === 'content:encoded'
    );

    parsedItems.push({
      title: item.querySelector('title')?.textContent || '',
      link: item.querySelector('link')?.textContent || '',
      description: item.querySelector('description')?.textContent || '',
      pubDate: item.querySelector('pubDate')?.textContent || '',
      category: item.querySelector('category')?.textContent || '',
      content: contentNode?.textContent || ''
    });
  });

  // console.log('Parsed items:', parsedItems);

  return parsedItems;
}

export function filterAndLimitItems(items, category, limit) {
  const filtered = items.filter(item => item.category === category);
  return limit ? filtered.slice(0, limit) : filtered;
} 