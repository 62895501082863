import React from 'react';
import { useParams } from 'react-router-dom';
import './ContentDisplay.css';
import { extractSlug } from '../../utils/urlUtils';

const ContentDisplay = ({ items, type }) => {
  const { id } = useParams();
  const item = items.find(item => extractSlug(item.link) === id);

  if (!item) {
    return <div className="content-display">Item not found</div>;
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const cleanAndFormatContent = (htmlContent) => {
    const temp = document.createElement('div');
    temp.innerHTML = htmlContent;

    // Remove unwanted elements
    const unwantedSelectors = [
      '.sharedaddy', 
      '.jp-relatedposts', 
      '.wp-block-separator',
      '.wp-block-spacer'
    ];
    
    unwantedSelectors.forEach(selector => {
      temp.querySelectorAll(selector).forEach(el => el.remove());
    });

    // Remove <br> tags
    temp.querySelectorAll('br').forEach(br => br.remove());

    // Format paragraphs
    temp.querySelectorAll('p').forEach(p => {
      if (!p.textContent.trim()) p.remove(); // Remove empty paragraphs
      // Remove the "first appeared on" text
      if (p.textContent.includes('first appeared on mediumorchid-rhinoceros-290244.hostingersite.com')) {
        p.remove();
      }
    });

    // Handle images
    temp.querySelectorAll('img').forEach(img => {
      const figure = document.createElement('figure');
      figure.className = 'content-image';
      const caption = document.createElement('figcaption');
      caption.textContent = img.alt || '';
      figure.appendChild(img.cloneNode(true));
      if (img.alt) figure.appendChild(caption);
      img.parentNode.replaceChild(figure, img);
    });

    // Handle blockquotes
    temp.querySelectorAll('blockquote').forEach(quote => {
      quote.className = 'content-quote';
    });

    return temp.innerHTML;
  };

  return (
    <article className="content-display">
      <header className="content-header">
        <h1>{item.title}</h1>
        <div className="content-meta">
          <span className="content-date">{formatDate(item.pubDate)}</span>
          <span className="content-category">{type}</span>
        </div>
      </header>
      <div 
        className="content-body"
        dangerouslySetInnerHTML={{ __html: cleanAndFormatContent(item.content) }}
      />
    </article>
  );
};

export default ContentDisplay; 