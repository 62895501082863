import React, { useEffect, useState } from 'react';
import StoryCard from '../components/StoryCard/StoryCard';
import { fetchRSSFeed, filterAndLimitItems } from '../services/rssService';
import Pagination from '../components/Pagination/Pagination';
import './Stories.css';

const ITEMS_PER_PAGE = 10;

const Stories = () => {
  const [stories, setStories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const loadStories = async () => {
      const items = await fetchRSSFeed();
      const filteredStories = filterAndLimitItems(items, 'Stories');
      setStories(filteredStories);
      setTotalPages(Math.ceil(filteredStories.length / ITEMS_PER_PAGE));
    };
    loadStories();
  }, []);

  const getCurrentPageStories = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return stories.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  return (
    <div className="stories-page" style={{ padding: '2rem 4rem' }}>
      <h2 className="page-title">Stories</h2>
      <div className="stories-grid">
        {getCurrentPageStories().map(story => (
          <StoryCard
            key={story.link}
            title={story.title}
            description={story.description}
            link={story.link}
            content={story.content}
            className="story-grid-item"
          />
        ))}
      </div>
      <Pagination 
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default Stories;
