import React from 'react';
import { Link } from 'react-router-dom';
import { extractFirstImageUrl } from '../../utils/contentUtils';
import { extractSlug } from '../../utils/urlUtils';
import './StoryCard.css';

const StoryCard = ({ title, description, link, content }) => {
  const stripHtml = (html) => {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    // Remove the "first appeared on" text if present
    temp.querySelectorAll('p').forEach(p => {
      if (p.textContent.includes('first appeared on mediumorchid-rhinoceros-290244.hostingersite.com')) {
        p.remove();
      }
    });
    const plainText = temp.textContent || temp.innerText || '';
    return plainText.split('[...]')[0].trim();
  };

  const imageUrl = extractFirstImageUrl(content);

  return (
    <Link to={`/stories/${extractSlug(link)}`} className="story-card">
      {imageUrl && (
        <div className="story-image">
          <img src={imageUrl} alt={title} />
        </div>
      )}
      <div className="story-content">
        <h3>{title}</h3>
        <p>{stripHtml(description)}</p>
      </div>
    </Link>
  );
};

export default StoryCard;
