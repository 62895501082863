import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import Poetry from './pages/Poetry';
import Stories from './pages/Stories';
import ContentDisplay from './components/ContentDisplay/ContentDisplay';
import { fetchRSSFeed } from './services/rssService';
import './App.css';

function App() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const fetchedItems = await fetchRSSFeed();
      setItems(fetchedItems);
    };
    loadItems();
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/poetry" element={<Poetry />} />
          <Route path="/stories" element={<Stories />} />
          <Route 
            path="/poetry/:id" 
            element={<ContentDisplay items={items} type="Poetry" />} 
          />
          <Route 
            path="/stories/:id" 
            element={<ContentDisplay items={items} type="Story" />} 
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
