import React, { useEffect, useState } from 'react';
import PoetryCard from '../components/PoetryCard/PoetryCard';
import { fetchRSSFeed, filterAndLimitItems } from '../services/rssService';
import Pagination from '../components/Pagination/Pagination';

const ITEMS_PER_PAGE = 10;

const Poems = () => {
  const [poems, setPoems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const loadPoems = async () => {
      const items = await fetchRSSFeed();
      const filteredPoems = filterAndLimitItems(items, 'Poems');
      console.log(filteredPoems);
      setPoems(filteredPoems);
      setTotalPages(Math.ceil(filteredPoems.length / ITEMS_PER_PAGE));
    };
    loadPoems();
  }, []);

  const getCurrentPagePoems = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return poems.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  return (
    <div className="poems-page" style={{ padding: '2rem 4rem' }}>
      <h2 className="page-title">Poems</h2>
      <div className="poems-grid">
        {getCurrentPagePoems().map(poem => (
          <PoetryCard
            key={poem.link}
            title={poem.title}
            description={poem.description}
            link={poem.link}
            content={poem.content}
          />
        ))}
      </div>
      <Pagination 
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default Poems;